/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const scrollTo = require('./src/utils/scrollto.js');

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { hash } = location;

  if (hash.includes('#')) {
    const id = hash.slice(1);
    const coords = getSavedScrollPosition(location);
    const [x, y] = coords || [0, 0];

    setTimeout(() => {
      window.scrollTo(x, y);
      scrollTo(`[data-target="${id}"]`, 800);
    }, 0);
  } else {
    window.scrollTo(0, 0);
  }

  return false;
};

exports.onRouteUpdate = ({ location }) => {
  const { hash } = location;

  if (hash.includes('#')) {
    const id = hash.slice(1);
    setTimeout(() => {
      scrollTo(`[data-target="${id}"]`, 800);
    }, 100);
  }
};
