/**
 * Scroll to target element
 *
 * @param {string|number} target - Selector or position
 * @param {number} duration - time (ms)
 */
function scrollTo(target, duration) {
  let targetPosition;

  if (typeof target === 'number') {
    targetPosition = target;
  } else {
    targetPosition = document.querySelector(target);
  }

  if (!targetPosition) return;

  let animation;
  let step;
  const { pageYOffset } = window;
  let currentPosition = pageYOffset;
  let elementOffset = targetPosition.offsetTop || targetPosition;
  const parts = Math.round(duration / 16);
  const isScrollDown = pageYOffset < elementOffset;
  const header = document.querySelector('.main-header');
  const headerHeight = header ? header.offsetHeight : 0;

  elementOffset -= headerHeight;

  if (isScrollDown) {
    step = elementOffset / parts;
  } else {
    step = (pageYOffset - elementOffset) / parts;
  }

  const interval = setInterval(() => {
    const stop = isScrollDown
      ? currentPosition < elementOffset
      : currentPosition > elementOffset;
    currentPosition = +(isScrollDown
      ? currentPosition + step
      : currentPosition - step).toFixed(2);

    if (stop) {
      animation = requestAnimationFrame(
        () => window.scrollTo(0, Math.round(currentPosition)),
      );
    } else {
      clearInterval(interval);
      cancelAnimationFrame(animation);
    }
  }, 16);
}

module.exports = scrollTo;
